import { useState, useRef, useEffect } from 'react';
import { partialProductSearch } from '../../api/product';
import { IoMdClose } from "react-icons/io";
import { Link } from 'react-router-dom';

const AutoSearch = ({ 
  placeholder = "Search...",
  minSearchLength = 1,
  onSelect,
  setSearchTerm,
  onSearch,
  searchTerm,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItem] = useState([]);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const debounceTimer = setTimeout(async () => {
      if (searchTerm?.length >= minSearchLength) {
        try {
            setIsLoading(true);
            const response =  await partialProductSearch(searchTerm);
            console.log(response);
            setItem(response.data);
            
          } catch (error) {
            setItem([]);
            
          }finally{
            setIsLoading(false);
          }
      }
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm, minSearchLength, onSearch]);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const handleSelect = (item) => {
    if (onSelect) {
      onSelect(item);
      setSearchTerm(item?.name)
    }
    setIsOpen(false);
  };

  const clearSearch = () => {
    if (setSearchTerm) {
      setSearchTerm('');
    }
    inputRef.current?.focus();
  };

  return (
    <div 
      className="relative w-full max-w-md"
      ref={dropdownRef}
      role="combobox"
      aria-expanded={isOpen}
      aria-haspopup="listbox"
    >
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={searchTerm || ''}
          onChange={(e) => {
            if (setSearchTerm) {
              setSearchTerm(e.target.value);
            }
            setIsOpen(true);
          }}
          onFocus={() => setIsOpen(true)}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className="w-full px-4 py-2 pr-12 text-gray-700 bg-white border rounded-lg 
                   transition-all duration-200 ease-in-out
                   focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
          role="searchbox"
          aria-autocomplete="list"
        />
        
        {/* Clear button */}
        {searchTerm && (
          <button
            onClick={clearSearch}
            className="absolute right-8 top-2.5 p-0.5 bg-white
                     transition-colors duration-200 text-black"
            aria-label="Clear search"
          >
           <IoMdClose/>
          </button>
        )}

        {/* Search icon or loading spinner */}
        <div className="absolute right-3 top-2.5">
          {isLoading ? (
            <div className="animate-spin h-5 w-5 border-2 border-blue-500 border-t-transparent rounded-full" />
          ) : (
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            >
              <circle cx="11" cy="11" r="8" />
              <path d="m21 21-4.3-4.3" />
            </svg>
          )}
        </div>
      </div>

      {/* Dropdown */}
      {isOpen && (
        <div 
          className="absolute z-40 w-full mt-1 flex flex-col gap-4 max-h-[500px] bg-white border rounded-lg shadow-lg 
                     transform transition-all duration-200 ease-in-out overflow-hidden p-3"
          role="listbox"
        >
          {/* Search results */}
          {items?.map((item) => (
            <Link
              key={item.id}
             to={`/product/${item?.brandName}/${item?.name|| item?.productName}`}
              className="px-4 py-2 cursor-pointer transition-colors duration-150 hover:bg-gray-50 flex justify-start items-center gap-3 w-full p-1  shadow-md border border-gray-50 rounded-lg"
              role="option"
            >
              <img src={item?.productImages?.split(",")[0]} alt={item?.name} className='w-14 h-14 rounded-md border-1 border-grey shadow-xs p-1 object-contain'/>
              <div cl>
              <div className="font-medium text-md  text-black"><span>{item?.name || item?.productName}</span>
             </div>
              <div className='text-md font-medium text-black'>{item?.brandName}</div>
              </div>
            </Link>
          ))}

          {/* Empty state */}
          {searchTerm && items?.length === 0 && (
            <div className="px-4 py-3 text-gray-500 text-center">
              No results found for "{searchTerm}"
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AutoSearch;