import FourthSection from "../Components/Home/FourthSection";
import { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import { getLocation, getProduct, getProductWithJava } from "../api/product";
import { useSelector } from "react-redux";
import { Flex, Spin } from "antd";
import ProductCompare from "../Components/Products/ProductCompare";
import ProductDetailsHeader from "../Components/ProductDetail/ProductDetailsHeader";
const boxStyle = {
  width: '100%',
  height: '100vh'
};


const ProductDetailUpdated = () => {
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProductCompare, setIsProductCompare] = useState(false);
  const [isUnavailable, setIsUnavailable] = useState(false);
  const [userPinCode, setUserPinCode] = useState(null)
  const authState = useSelector((state) => (state ? state.auth : []));
  const location =useLocation();


  const fetchProductDetails = async (brandName,productName) => {
    try {
      const response = await getProductWithJava(brandName,productName);
      const userDataPin = await getLocation(response?.data?.id)
      setUserPinCode(userDataPin?.address?.pincode);
      const isAvaiable = userDataPin?.availableDealers?.lenght>0 ? true : false
      setIsUnavailable(!isAvaiable)
      setProduct(response?.data); // Assuming the API response contains the product details
      setIsLoading(false)
    } catch (error) {
      // console.error("Error fetching product details:", error);
      setIsLoading(false)
    }
  };

  useEffect(() => {
    if(!location.pathname)return;
    const [base,baseProduct,brandName,productName] = location.pathname.split('/');
    if(brandName && productName){
      fetchProductDetails(brandName,productName);
    }
    
     window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <>
      {isLoading ? (
        <Flex gap="middle" align="start" vertical>
          <Flex style={boxStyle} justify={"center"} align={"center"}>
            <Spin />
          </Flex>
        </Flex>
      ) : (
        <>
          {isProductCompare ? (
            <div style={{ marginBottom: 20 }}>
              <ProductCompare
                setIsProductCompare={setIsProductCompare}
                defaultProduct={product}
                userPinCode={userPinCode ? userPinCode : authState.userPincode?.pincode}
              />
            </div>
          ) : (
            <>
              {product ? (
                <div style={{ width: '100%', overflowX: "hidden", backgroundColor: "#1D1E18" }}>
                  <ProductDetailsHeader setIsUnavailable={setIsUnavailable} productData={product} isUnavailable={isUnavailable} setIsProductCompare={setIsProductCompare} setUserPinCode={setUserPinCode}  userPincode={userPinCode}/>
                </div>
              ) : (
                <></>
              )}
              {/* <ClientTestimonials /> */}
            </>
          )}
        </>
      )}
    </>
  );
};
export default ProductDetailUpdated;
